import React from 'react'
import { graphql } from 'gatsby'
import img from '../../static/images/bg_1.jpg';
import PageHeader from '../components/PageHeader'
import Content from '../components/Content'
import Layout from '../components/Layout'
import Image from '../components/Image';
import Accordion from '../components/Accordion';
import nadar from '../../static/images/nadar pic.jpg';
import lewin from '../../static/images/lewin.png';
import './AboutPage.css';

// Export Template for use in CMS preview
export const FormsPageTemplate = ({ page, title, subtitle, featuredImage, body, file }) => (
  <main className="Home">
    <PageHeader
      large
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
    />
   
   <section className="section">
      <div className="container">
   
      <h3>Patient Forms</h3>
      <p>Download any of the necessary forms before your visit.</p>



      </div>
    </section>
    <section className="section">
      <div className="container">
      <ul>
      {file.map(x => {
         
          return <li><a href={x.node.publicURL} download>Download {x.node.name}</a> </li>
      })}
      </ul>
      </div>
    </section>



    <section className="section">
      <div className="container">
     {/* <h2>Why Choose Capital Area Cardiovascular Associates?</h2> */}
      {/* <Content source={body} /> */}
     
    
      {/* <h2>Meet Dr. Nadar</h2>
      <Image alt="Capital Area Research Team" src={nadar} resolutions="400x" className="care-group"/>
      <Content source={meetNadar} />
        
        <h2>Meet Dr. Lewin</h2>
        <Image alt="Capital Area Research Team" src={lewin} resolutions="small" className="care-group"/>
        <Content source={meetLewin} />
        <h2>Capital Area Research</h2>
        <Content source={care} /> */}
        {/* <Content source={employeeList} />
        <div className="container">
          <Accordion items={accordion} />
        </div>
        <Image alt="Capital Area Research Team, Newport" src={amy} resolutions="small" className="care-group"/>
        <Content source={employeeList2} /> */}
        {/* <div className="container">
            <Accordion items={accordion2} />
        </div> */}
      </div>
    </section>


  </main>
)

// Export Default HomePage for front-end
const FormsPage = ({ data: { page, files}}) => (
  <Layout meta={page.frontmatter.meta || false}>
    <FormsPageTemplate {...page} file={files.edges} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default FormsPage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query FormsPage($id: String!) {
    files: allFile(filter: { extension: { eq: "pdf" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
        meta {
          description
          title
          image
        }
      }
    }
  }
`
